// This code shall only be imported and used on server side.
// We do not use the 'server-only' import here, because it prevents
// the function from being used in the middleware.
import { Err } from 'utils/response'

// Get the needed configuration values.
//const apiBaseUrl = process.env.ENPAL_PUBLIC_OPENIBAN_API_BASE_URL
const apiBaseUrl = "https://openiban.com/validate"

// Ensure all needed environment variables are set.
if (!apiBaseUrl) {
  throw new Error(
    'Missing configuration for OpenIBAN API. Please check your environment variables.'
  )
}

/**
 * Sends a request to the CraftOS Buildability API.
 *
 * @param {string} relativeUrl The relative URL to send the request to.
 * @param {HttpMethod} method The HTTP method to use for the request.
 * @returns {Promise<T>} The response from the OpenIBAN API.
 */
async function sendRequest<T>(
  relativeUrl: string,
): Promise<T> {
  // Fetch the bank data.
  const response = await fetch(`${apiBaseUrl}/${relativeUrl}`, {
    method: 'GET',
    cache: 'no-cache',
    redirect: 'follow'
  })

  // Handle the response.
  if (!response.ok) {
    throw Err(
      `OpenIBAN API responded with status code ${response.status}`
    )
  }
  return (await response.json()) as T
}

export default sendRequest
