import { useQuery } from '@tanstack/react-query'
import sendRequest from 'utils/openiban-api/request'
import { bankDataQueryKey } from 'reactQuery/queryKeys'

/**
 * Retrieve bank data by IBAN.
 *
 * @param {string} iban The IBAN to query for.
 * @returns {any} The bank data object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const bankDataQueryFn = async (iban: string): Promise<any> => {
    return await sendRequest(`${iban}?getBIC=true&validateBankCode=true`)
}

/**
 * Query hook to retrieve bank data by IBAN.
 *
 * @param {string} iban The iban to query for.
 * @returns {any} The query result.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useBankData = (iban: string): any =>
  useQuery({
    queryKey: bankDataQueryKey(iban),
    queryFn: async () => {
      if (!iban) {
        return null
      }
      return await bankDataQueryFn(iban)
    }
  })

export { bankDataQueryFn, useBankData }
