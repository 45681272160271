interface BaseResponse<T, E> {
  readonly ok: boolean
  readonly err: boolean
  readonly val: T | E
}

export class ErrImpl<E> implements BaseResponse<never, E> {
  static readonly EMPTY = new ErrImpl<void>(undefined)

  readonly ok!: false
  readonly err!: true
  readonly val!: E

  private readonly _stack!: string

  constructor(val: E) {
    if (!(this instanceof ErrImpl)) {
      return new ErrImpl(val)
    }

    this.ok = false
    this.err = true
    this.val = val

    const stackLines = new Error().stack?.split('\n').slice(2)
    if (
      stackLines &&
      stackLines.length > 0 &&
      stackLines[0].includes('ApiError')
    ) {
      stackLines.shift()
    }

    if (stackLines) {
      this._stack = stackLines?.join('\n')
    }
  }

  get stack() {
    return this._stack
  }
}

export class OkImpl<T> implements BaseResponse<T, never> {
  static readonly EMPTY = new OkImpl<void>(undefined)

  readonly ok!: true
  readonly err!: false
  readonly val!: T

  constructor(val: T) {
    if (!(this instanceof OkImpl)) {
      return new OkImpl(val)
    }

    this.ok = true
    this.err = false
    this.val = val
  }
}

export const Ok = <T>(val: T) => new OkImpl<T>(val)
export type Ok<T> = OkImpl<T>
export const Err = <E>(val: E) => new ErrImpl<E>(val)
export type Err<T> = ErrImpl<T>
export type Result<T, E> = Ok<T> | Err<E>
